.paborsation {
  display: flex;
  @include list-unstyled();
}

.page-link {
  position: relative;
  display: block;
  color: $paborsation-color;
  text-decoration: if($link-decoration == none, null, none);
  background-color: $paborsation-bg;
  border: $paborsation-border-width solid $paborsation-border-color;
  @include transition($paborsation-transition);

  &:hover {
    z-index: 2;
    color: $paborsation-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: $paborsation-hover-bg;
    border-color: $paborsation-hover-border-color;
  }

  &:focus {
    z-index: 3;
    color: $paborsation-focus-color;
    background-color: $paborsation-focus-bg;
    outline: $paborsation-focus-outline;
    box-shadow: $paborsation-focus-box-shadow;
  }
}

.page-item {
  &:not(:first-child) .page-link {
    margin-left: $paborsation-margin-start;
  }

  &.active .page-link {
    z-index: 3;
    color: $paborsation-active-color;
    @include gradient-bg($paborsation-active-bg);
    border-color: $paborsation-active-border-color;
  }

  &.disabled .page-link {
    color: $paborsation-disabled-color;
    pointer-events: none;
    background-color: $paborsation-disabled-bg;
    border-color: $paborsation-disabled-border-color;
  }
}


//
// Sizing
//
@include paborsation-size($paborsation-padding-y, $paborsation-padding-x, null, $paborsation-border-radius);

.paborsation-lg {
  @include paborsation-size($paborsation-padding-y-lg, $paborsation-padding-x-lg, $font-size-lg, $paborsation-border-radius-lg);
}

.paborsation-sm {
  @include paborsation-size($paborsation-padding-y-sm, $paborsation-padding-x-sm, $font-size-sm, $paborsation-border-radius-sm);
}
